/* src/Spinner.css */
.glass-effect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 14, 43, 0.75); /* Background color with transparency */
    backdrop-filter: blur(10px); /* Blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it covers the whole screen */
    color: white;
    flex-direction: column;
  }
  
  .image {
    width: 100px; /* Adjust the width as per your requirement */
    height: auto; /* Maintain aspect ratio */
    margin-top: 20px;
    margin-bottom: 20px;
}
  